const adminEndURL = {
  ADMIN_LOGIN: "admin/adminLogin",
  ADMIN_CHANGE_PASSWORD: "admin/changePassword",
  OWNER_RESET_PASSWORD: "admin/owner/resetPassword",
  ACTIVE_USERS: "admin/viewDashboard",
  ADD_KEY_HOST: "admin/addKeyHost",
  UPDATE_KEY_HOST: "admin/updateKeyHost",
  ADD_BOX: "admin/addBox",
  UPADTE_BOX: "admin/updateBox",
  GET_BOXES: "admin/getBoxD",
  GET_BOX_LIST: "admin/getBox",
  GET_BOX_DETAIL: "admin/boxDetail",
  CUBICLE_STATUS: "admin/action",
  UPDATE_BOX_STATUS: "admin/updateBoxStatus",
  ADD_CUBICLE_DETAIL: "admin/addCubicleDetail",
  ADD_COUPON: "admin/addCoupon",
  GET_COUPONS: "admin/getCoupons",
  UPDATE_COUPON: "admin/editCoupons",
  DELETE_COUPON: "admin/deleteCoupon",
  GET_OWNER_LIST: "admin/getKeyOwners",
  GET_OWNER_DETAILS: "admin/ownerDetails",
  UPDATE_OWNER: "admin/updateOwner",
  GET_KEY_HOST: "admin/getKeyHost",
  GET_KEY_HOST_DETAILS: "admin/getKeyHostDetails",
  UPDATE_OWNER_STATUS: "admin/updateOwnerStatus",
  UPDATE_KEY_HOST_STATUS: "admin/updateKeyHostStatus",
  GET_KEY: "admin/getKeys",
  GET_KEY_DETAILS: "admin/getKeyDetails",
  SEND_NOTIFICAION: "admin/sendNotification",
  GET_PLANS: "admin/getplans",
  ADD_PLAN: "admin/addPlan",
  EDIT_PRICE: "admin/editPrice",
  PLAN_STATUS: "admin/planStatus",
  DELETE_PLAN: "admin/deletePlan",
  KEY_HISTORY: "owner/key/history",
  KEY_HISTORY_COLLECTION: "owner/key/collection/history",
  GET_CONTENT: "admin/getContent",
  UPDATE_CONTENT: "admin/updateContent",
  GET_PROFILE: "admin/profile",
  UPDATE_PROFILE: "admin/updateAdminProfile",
  GET_TRANSACTION_LOGS: "admin/getTransactionLogs",
  GET_QUERIES: "admin/query",
  QUERY_ANSWER: "admin/give-answer",
  RESEND_PASS: "admin/resendPassword",
  CANCEL_SUBSCRIPTION: "admin/cancelKeySubsacription",
  CHANGE_KEY_HOST_PASSWORD: "admin/changeHostPassword",
  GET_REPORTS: "admin/report-keys",
  ADD_PROJECT_KEYS: "admin/addProjectKeys",
  GET_STRIPE_KEY: "admin/getStripeKey",
  DELETE_KEY: "owner/key",
  GET_HOST_REQUESTS: "admin/getHostRequests",
};

const ENDPOINTS = {
  ...adminEndURL,
};

export default ENDPOINTS;
